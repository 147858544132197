<template>
  <div class="tab-content">
    <div class="switch-title">
      <span>住院报告单</span>
    </div>
    <div class="pagination">
      <div class="pagination-scroll">
        <div class="date">
          <div class="date-item">
            <span>今日</span>
          </div>
          <div class="date-item">
            <span>近7日</span>
          </div>
          <div class="date-item">
            <span>近3月</span>
          </div>
          <el-select v-model="value" placeholder="请选择" size="small">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="date-scroll">
          <div class="date-box">
            <div class="date-list" v-for="(item, index) in 20" :key="index" @click="switchClick(index)">
              <div class="left"></div>
              <div class="center" :class="{ center1: switchIndex === index }"></div>
              <div class="right" :class="{ right1: switchIndex === index }">
                <div class="blue" :class="{ blue1: switchIndex === index }">
                  <span>新型冠状病毒核酸检测 </span>
                </div>
                <div class="bottom">
                  <span class="time">2021-05-22 08:55 </span>
                  <span>未出报告</span>
                </div>
              </div>
            </div>
            <!-- 第一个 -->
            <div class="position"></div>
            <!-- 最后一个 -->
            <div class="position1"></div>
          </div>
        </div>
      </div>
      <div class="pagination-table">
        <div class="table-info">
          <span>检验项目：新型冠状病毒核酸检测</span>
          <span>申请科室：五官科门诊</span>
          <span>医生：叶谓之</span>
          <span>送检时间：2021-05-22 08:55</span>
          <span>样本号：15</span>
          <span>样本类型：鼻咽拭子</span>
          <span>检验：宋叶月</span>
          <span>审核：王爱护</span>
          <span>报告时间：2021-05-22 08:55</span>
        </div>
        <el-table :data="tableData" :header-cell-style="{ background: '#D4D4D4', 'text-align': 'center', color: '#3E3E3E' }">
          <el-table-column prop="province" label="项目名称">
          </el-table-column>
          <el-table-column prop="city" label="英文标识">
          </el-table-column>
          <el-table-column prop="address" label="结果">
          </el-table-column>
          <el-table-column prop="zip" label="参考范围">
          </el-table-column>
          <el-table-column prop="zip" label="单位">
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableData: [{
        date: '2016-05-03',
        name: '',
        province: '',
        city: '',
        address: '',
        zip: 200333
      }],
      options: [{
        value: '',
        label: ''
      }],
      value: '检验报告单',
      switchIndex: 0
    }
  },
  methods: {
    switchClick (index) {
      this.switchIndex = index
    }
  }
}
</script>

<style lang="less" scoped>
.tab-content {
  width: 100%;
  height: 100%;

  .switch-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    border-bottom: 1px solid #A1A6BB;
    color: #3E3E3E;
    font-size: 18px;
  }

  .pagination {
    display: flex;
    width: 100%;
    height: calc(100%);

    .pagination-scroll {
      width: 300px;
      height: 100%;
      border-right: 1px solid #A1A6BB;
      box-sizing: border-box;

      .date {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 10px 2px;
        box-sizing: border-box;

        .date-item {
          padding: 0 4px;
          white-space: nowrap;
          color: #3E3E3E;
          font-size: 14px;
        }

        ::v-deep .el-select .el-input .el-select__caret {
          color: #3E3E3E;
        }
      }

      .date-scroll {
        overflow-y: scroll;
        width: 100%;
        height: calc(100% - 90px);

        &::-webkit-scrollbar {
          width: 0;
        }

        .date-box {
          position: relative;
          display: flex;
          flex-direction: column;
          margin-left: 10px;
          border-left: 2px dashed #A1A6BB;
          box-sizing: border-box;
        }

        .position {
          position: absolute;
          top: 2px;
          left: -1px;
          z-index: 88;
          width: 18px;
          height: 27px;
          background-color: #fff;
          border-bottom: 1px #666666 solid;
          box-sizing: border-box;
        }

        .position1 {
          position: absolute;
          bottom: 0;
          left: -1px;
          z-index: 88;
          width: 18px;
          height: 37px;
          background-color: #fff;
          border-top: 1px #666666 solid;
          box-sizing: border-box;
        }

        .date-list {
          display: flex;
          align-items: center;
          width: calc(100% - 20px);
          height: 54px;
          margin-bottom: 10px;
          cursor: pointer;

          .left {
            width: 18px;
            height: 2px;
            background-color: #A1A6BB;
          }

          .center {
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background-color: #666666;
          }

          .center1 {
            background-color: #1269B7;
          }

          .right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: calc(100% - 32px);
            height: 54px;
            padding: 5px;
            background-color: #D9D9D9;
            border-radius: 6px;
            box-sizing: border-box;

            .blue {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              color: #333333;
              font-size: 14px;
            }

            .blue1 {
              color: #1269B7;
            }

            .bottom {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              // display: flex;
              // align-items: center;
              // justify-content: space-between;
              color: #FF9200;
              font-size: 12px;

              span {
                white-space: nowrap;
              }

              .time {
                margin-right: 4px;
                color: #666666;
                font-size: 12px;
              }
            }
          }

          .right1 {
            background-color: #79B3E7;
          }
        }
      }
    }

    .pagination-table {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: calc(100% - 310px);
      height: 100%;
      margin-left: 10px;

      .table-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        color: #3E3E3E;
        font-size: 16px;
        font-weight: 800px;

        span {
          margin-top: 20px;
        }
      }

      .el-table {
        margin-top: 10px;
      }

      ::v-deep .el-table thead.is-group th.el-table__cell,
      ::v-deep .el-table--border,
      .el-table--group {
        border: 1px solid #707070;
      }

      ::v-deep .el-table td.el-table__cell {
        border: 1px solid #707070;
        color: #3E3E3E;
        text-align: center;
      }

      ::v-deep .el-table__row {
        border: 1px solid #707070;
      }

      ::v-deep .el-table th.el-table__cell.is-leaf {
        border: 1px solid #707070;
      }
    }
  }
}
</style>
