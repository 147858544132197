<template>
  <div class="medical">
    <div class="operate">
      <el-dropdown trigger="click" @command="handleCommand">
        <span class="el-dropdown-link">
        <i class="el-icon-s-tools el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="1">新建</el-dropdown-item>
        <el-dropdown-item command="2">保存</el-dropdown-item>
        <el-dropdown-item command="3">智能生成</el-dropdown-item>
        <el-dropdown-item command="4">存为模板</el-dropdown-item>
        <el-dropdown-item command="5">打印</el-dropdown-item>
        <el-dropdown-item command="6">续打</el-dropdown-item>
        <el-dropdown-item command="7">刷新</el-dropdown-item>
        <el-dropdown-item command="8" @close="close">返回</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    </div>
    <avue-ueditor v-model="text" v-bind="options"></avue-ueditor>
  </div>
</template>

<script>
export default {
  data () {
    return {
      text: '',
      options: {}
    }
  },
  watch: {
    text (news) {
      // console.log(news)
    }
  },
  methods: {
    handleCommand (command) {
      this.close()
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.medical {
  overflow-y: scroll;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 88;
  width: calc(100% - 290px);
  height: 100%;
  background-color: #fff;

  &::-webkit-scrollbar {
    width: 0;
  }

  .operate {
    position: absolute;
    top: 0;
    right: 10px;
    z-index: 8888;
    font-size: 26px;
  }

  ::v-deep .avue-ueditor {
    width: 100%;
    height: 100%;
  }

  ::v-deep.w-e-text-container,
  ::v-deep .w-e-text {
    height: 100% !important;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  ::v-deep.avue-ueditor {
    >div {
      width: 100%;
      height: calc(100% - 150px);
    }
  }
}
</style>
