<template>
  <div class="tab-content">
    <div class="switch-title">
      <span>手术记录</span>
      <div class="btns">
        <el-button size="small" @click="switchClick(0)" :class="{ active: switchIndex === 0 }">当月</el-button>
        <el-button size="small" @click="switchClick(1)" :class="{ active: switchIndex === 1 }">近3月</el-button>
        <el-button size="small" @click="switchClick(2)" :class="{ active: switchIndex === 2 }">近半年</el-button>
        <el-button size="small" @click="switchClick(3)" :class="{ active: switchIndex === 3 }">近1年</el-button>
      </div>
    </div>
    <el-timeline>
      <el-timeline-item timestamp="2021-05-22   08:55" placement="top">
        <div class="city">
          <span>健康无忧人民医院 五官科病房 张小光医师</span>
        </div>
        <div class="title">
          <span class="left"></span>
          <span>住院诊断</span>
        </div>
        <el-row :gutter="3">
          <el-col :span="3">
            <div class="col">
              <span>牙齿敏感证</span>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="col">深龋伴牙髓充血</div>
          </el-col>
          <el-col :span="3">
            <div class="col">航空性牙痛</div>
          </el-col>
        </el-row>
        <div class="title">
          <span class="left"></span>
          <span>手术情况</span>
        </div>
        <el-row :gutter="3">
          <el-col :span="3">
            <div class="col">
              <span>健康无忧中医院</span>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="col">五官科</div>
          </el-col>
          <el-col :span="4">
            <div class="col">张倩玉主任医师</div>
          </el-col>
          <el-col :span="3">
            <div class="col blue">查看手术报告</div>
          </el-col>
        </el-row>
      </el-timeline-item>
      <el-timeline-item>
      </el-timeline-item>
    </el-timeline>
    <div class="absolute">
      <el-timeline>
        <el-timeline-item timestamp="2021-05-22 08:55" placement="top" color='#1269B7'>
        </el-timeline-item>
        <el-timeline-item timestamp="2021-05-22 08:55" placement="top">
        </el-timeline-item>
        <el-timeline-item timestamp="2021-05-22 08:55" placement="top">
        </el-timeline-item>
      </el-timeline>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      switchIndex: 0
    }
  },
  methods: {
    switchClick (index) {
      this.switchIndex = index
    }
  }
}
</script>

<style lang="less" scoped>
.tab-content {
  position: relative;
  width: 100%;
  height: 100%;

  .switch-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 45px;
    border-bottom: 1px solid #A1A6BB;
    color: #3E3E3E;
    font-size: 18px;

    .btns {
      display: flex;
      align-items: center;

      .el-button {
        margin-right: 20px;
        background-color: #D9D9D9;
        border-radius: 0;
        border: none;
      }

      .active {
        background-color: #1269B7;
        color: #fff;
      }
    }
  }

  .el-timeline {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 50px);
    padding: 10px;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0;
    }

    ::v-deep .el-timeline-item__node--normal {
      width: 20px;
      height: 20px;
      background-color: #1269B7;
    }

    ::v-deep .el-timeline-item__tail {
      left: 7px;
      border-left: 3px dotted #A1A6BB;
    }

    ::v-deep .el-timeline-item__timestamp {
      color: #3E3E3E;
      font-size: 16px;
    }

    .city {
      color: #A1A6BB;
      font-size: 12px;
    }

    .title {
      display: flex;
      align-items: center;
      margin-top: 25px;
      margin-bottom: 6px;
      color: #3E3E3E;
      font-size: 14px;

      .left {
        width: 24px;
        height: 10px;
        margin-right: 10px;
        border-radius: 0 4px 4px 0;
        background-color: #D9D9D9;
      }
    }

    .el-row {
      padding-left: 34px;
      margin-bottom: 4px;
    }

    .col {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 54px;
      text-align: center;
      background-color: #D9D9D9;
      color: #666666;
      font-size: 14px;
    }

    .col1 {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .span {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .span1 {
        color: #A1A6BB;
        font-size: 12px;
      }
    }

    .blue {
      color: #1269B7;
    }

    .see {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-left: 36px;
      box-sizing: border-box;

      .see-title {
        color: #3E3E3E;
        font-size: 12px;
      }

      .see-box {
        position: relative;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        width: 116px;
        height: 130px;
        background-color: #CACACA;

        .image {
          width: 108px;
          height: 102px;
          margin-bottom: 10px;
          background-color: #000;
        }

        .tip {
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 88;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          padding: 4px;
          color: #FFFFFF;
          font-size: 12px;
          background-color: rgba(0, 0, 0, .69);
        }
      }
    }
  }

  .absolute {
    position: absolute;
    top: 80px;
    right: 0;
    z-index: 88;
    padding-top: 10px;
    background-color: #fff;
    box-shadow: 0px 2px 4px #666666;

    ::v-deep .el-timeline-item__node--normal {
      width: 20px;
      height: 20px;
      background-color: #AAAAAA;
    }

    .el-timeline-item__node--normal:nth-child(0) {
      background-color: #1269B7;
    }

    ::v-deep .el-timeline-item__tail {
      left: 7px;
      border-left: 3px dotted #A1A6BB;
    }
  }
}
</style>
