<template>
  <div class="patientManage">
    <div class="patient-search">
      <el-input placeholder="搜索" suffix-icon="el-icon-search"></el-input>
    </div>
    <div class="patient-box">
      <div class="patient-tab">
        <div class="title">
          <span>病区患者</span>
        </div>
        <el-button v-for="(item, index) in tabs" :key="index" :class="{ blue: index === 0 }">{{ item }}</el-button>
      </div>
      <div class="scroll">
        <el-row :gutter="20">
          <el-col :span="6" v-for="(item, index) in 5" :key="index">
            <div class="col" @click="openTabBox">
              <div class="col-line">
                <img class="image" src="../../img/emr/user.png">
                <div class="info">
                  <div class="yellow">
                    <span>T-4床</span>
                  </div>
                  <div class="black color">
                    <span>女</span>
                  </div>
                  <div class="black">
                    <span>44岁3月</span>
                  </div>
                  <div class="black">
                    <span>ll护理</span>
                  </div>
                  <div class="black">
                    <span>202111606032</span>
                  </div>
                </div>
              </div>
              <div class="col-line">
                <div class="border-bottom">
                  <span>诊断：慢性鼻炎，慢性咽炎</span>
                </div>
              </div>
              <div class="col-line col-margin">
                <el-button size="small">居民医保</el-button>
                <div class="tag">
                  <span>ICU</span>
                </div>
                <div class="tag tag-red">
                  <span>敏</span>
                </div>
                <div class="tag tag-yellow">
                  <span>慢</span>
                </div>
                <div class="tag">
                  <span>术</span>
                </div>
                <div class="tag">
                  <span>CV</span>
                </div>
                <div class="tag">
                  <span>热</span>
                </div>
              </div>
              <div class="col-line col-margin">
                <el-button size="small">住院(12)</el-button>
                <div class="tag">
                  <span>瘫</span>
                </div>
                <div class="tag">
                  <span>跌</span>
                </div>
                <div class="tag">
                  <span>疮</span>
                </div>
                <div class="tag">
                  <span>昏</span>
                </div>
                <div class="tag">
                  <span>传</span>
                </div>
                <div class="tag">
                  <span>感</span>
                </div>
              </div>
              <div class="col-line col-margin">
                <el-button size="small" class="button-blue">液(50%)</el-button>
                <div class="tag">
                  <span>孕</span>
                </div>
                <div class="tag">
                  <span>透</span>
                </div>
                <div class="tag tag1"></div>
                <div class="tag tag1"></div>
                <div class="tag tag1"></div>
                <div class="tag tag1"></div>
              </div>
              <div class="col-line col-margin">
                <div class="tiem">
                  <span>2021-05-25</span>
                </div>
                <div class="name">
                  <img class="name-img" src="../../img/patientManage/user1.png">
                  <span>叶谓之</span>
                </div>
                <div class="name">
                  <img class="name-img" src="../../img/patientManage/user2.png">
                  <span>叶谓之</span>
                </div>
              </div>
              <div class="die" v-if="index === 4">
                <img src="../../img/patientManage/die.png">
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <TabBox v-if="showTabBox" @close="closeTabBox"></TabBox>
  </div>
</template>

<script>
import TabBox from './tabBox.vue'
export default {
  components: {
    TabBox
  },
  data () {
    return {
      tabs: Object.freeze(['我的患者(5)', '科室患者(1)', '今日入院(1)', '今日出院(1)', '今日会诊(1)']),
      showTabBox: true
    }
  },
  methods: {
    openTabBox () {
      // console.log(11111)
      this.showTabBox = true
    },
    closeTabBox () {
      this.showTabBox = false
    }
  }
}
</script>

<style lang="less" scoped>
.patientManage {
  position: relative;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  background-color: #fff;
  padding: 10px;
  box-sizing: border-box;

  .patient-search {
    padding: 4px 0;

    .el-input {
      width: 246px;
    }

    ::v-deep.el-input__inner {
      background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
      border-radius: 6px;
      border: 1px solid #1F72D3;
      color: #fff;

      &::-webkit-input-placeholder {
        color: #fff;
        font-size: 18px;
      }
    }

    ::v-deep .el-input__suffix {
      color: #fff;
      font-size: 18px;
    }
  }

  .patient-box {
    width: 100%;
    height: calc(100% - 60px);
    margin-top: 4px;
    padding: 10px 18px;
    background-color: #fff;
    box-sizing: border-box;

    .patient-tab {
      display: flex;
      align-items: center;
      padding: 6px 0;
      border-bottom: 1px solid #A1A6BB;

      .title {
        color: #333333;
        margin-right: 20px;
        font-size: 18px;
        font-weight: bold;
      }

      .el-button {
        border-color: #1269B7;
        border-radius: 0;
        color: #1269B7;
      }

      .blue {
        color: #fff;
        background-color: #1269B7;
      }
    }

    .scroll {
      overflow-y: scroll;
      width: 100%;
      height: calc(100% - 75px);
      margin-top: 10px;

      &::-webkit-scrollbar {
        width: 0;
      }

      .el-row {
        .col {
          overflow: hidden;
          position: relative;
          width: calc(100% - 16px);
          height: calc(100% - 16px);
          margin-bottom: 10px;
          padding: 18px 7px;
          border: 1px solid #A1A6BB;
          border-radius: 10px;
          cursor: pointer;

          .col-line {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #333333;
            font-size: 16px;

            .image {
              width: 50px;
              height: 50px;
            }

            .info {
              display: flex;
              align-items: center;
              justify-content: space-between;
              flex-wrap: wrap;
              width: calc(100% - 60px);
              margin-left: 10px;

              .yellow {
                display: block;
                margin-right: 6px;
                padding: 2px 8px;
                background-color: #FF9200;
                border-radius: 17px;
                color: #fff;
                font-size: 17px;
              }

              .black {
                margin-right: 6px;
                color: #333;
                font-size: 16px;
              }

              .color {
                color: #FF00F7;
              }
            }

            .border-bottom {
              width: 100%;
              padding: 8px 0;
              color: #333;
              font-size: 16px;
              border-bottom: 1px solid #A1A6BB;
            }

            .el-button {
              border-color: #1269B7;
              border-radius: 0;
              color: #1269B7;
            }

            .button-blue {
              background-color: #1269B7;
              color: #fff;
            }

            .tag {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 33px;
              height: 33px;
              padding: 0 4px;
              background-color: #D9D9D9;
              white-space: nowrap;
              color: #666666;
              font-size: 16px;
            }

            .tag1 {
              background-color: #fff;
            }

            .tag-red {
              width: 31px;
              height: 31px;
              background-color: #FFB093;
              border: 1px solid #FF4907;
              color: #FF4907;
            }

            .tag-yellow {
              width: 31px;
              height: 31px;
              background-color: #FFCA83;
              border: 1px solid #FF9200;
              color: #FF9200;
            }

            .time {
              color: #333;
              font-size: 16px;
            }

            .name {
              display: flex;
              align-items: center;
              color: #333;
              font-size: 16px;

              .name-img {
                width: 18px;
                height: 18px;
                margin-right: 4px;
              }
            }
          }

          .col-margin {
            margin-top: 10px;
          }

          &::after {
            position: absolute;
            left: -48px;
            bottom: 0px;
            z-index: 888;
            padding: 6px 50px;
            content: '欠费';
            background-color: #FF8356;
            color: #fff;
            font-size: 18px;
            transform: rotate(35deg);
          }

          .die {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 999;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            background-color: rgba(161, 166, 187, .8);

            .die-img {
              width: 300px;
              height: 160px;
            }
          }
        }
      }
    }
  }
}
</style>
