<template>
  <div class="tab-content">
    <div class="switch-title">
      <span>护理文书</span>
    </div>
    <div class="pagination">
      <div class="pagination-scroll">
        <div class="cell" v-for="(item, index) in 30" :key="index" @click="switchClick(index)" :class="{ cell1: switchIndex === index }">
          <img class="img" src="../../img/patientManage/book1.png" v-if="switchIndex === index">
          <img class="img" src="../../img/patientManage/book.png" v-else>
          <span>成人体温表</span>
        </div>
      </div>
      <div class="pagination-table">
        <div class="box-scroll">
          <div class="box-title">
            <span>成人体温单</span>
          </div>
          <div class="box-list" v-for="(item, index) in 8" :key="index">
            <div class="table"></div>
            <div class="number">第{{ item }}页</div>
          </div>
        </div>
        <div class="box-table">
          <div class="table-title">
            <span>健康无忧人民医院</span>
          </div>
          <div class="table-line">
            <span>长期医嘱单</span>
          </div>
          <div class="table-info">
            <span>姓名：[李艳艳]</span>
            <span>年龄：[44岁3月]</span>
            <span>床号：[T-4]</span>
            <span>科室：[五官科]</span>
            <span>病区：[五官科一病区]</span>
            <span>住院号：[202005562008]</span>
          </div>
          <el-table :data="tableData"
            :header-cell-style="{ background: '#fff', 'text-align': 'center', color: '#3E3E3E' }" height="350">
            <el-table-column label="起始">
              <el-table-column prop="province" label="年月日时分">
              </el-table-column>
              <el-table-column prop="city" label="医嘱内容 ">
              </el-table-column>
              <el-table-column prop="address" label="医师签名">
              </el-table-column>
              <el-table-column prop="zip" label="护士签名">
              </el-table-column>
            </el-table-column>
            <el-table-column label="停止">
              <el-table-column prop="province" label="年月日时分">
              </el-table-column>
              <el-table-column prop="city" label="医师签名">
              </el-table-column>
              <el-table-column prop="address" label="护士签名">
              </el-table-column>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableData: [{
        date: '2016-05-03',
        name: '',
        province: '',
        city: '',
        address: '',
        zip: 200333
      }, {
        date: '2016-05-03',
        name: '',
        province: '',
        city: '',
        address: '',
        zip: 200333
      }, {
        date: '2016-05-03',
        name: '',
        province: '',
        city: '',
        address: '',
        zip: 200333
      }, {
        date: '2016-05-03',
        name: '',
        province: '',
        city: '',
        address: '',
        zip: 200333
      }, {
        date: '2016-05-03',
        name: '',
        province: '',
        city: '',
        address: '',
        zip: 200333
      }, {
        date: '2016-05-03',
        name: '',
        province: '',
        city: '',
        address: '',
        zip: 200333
      }, {
        date: '2016-05-03',
        name: '',
        province: '',
        city: '',
        address: '',
        zip: 200333
      }, {
        date: '2016-05-03',
        name: '',
        province: '',
        city: '',
        address: '',
        zip: 200333
      }, {
        date: '2016-05-03',
        name: '',
        province: '',
        city: '',
        address: '',
        zip: 200333
      }, {
        date: '2016-05-03',
        name: '',
        province: '',
        city: '',
        address: '',
        zip: 200333
      }, {
        date: '2016-05-03',
        name: '',
        province: '',
        city: '',
        address: '',
        zip: 200333
      }, {
        date: '2016-05-03',
        name: '',
        province: '',
        city: '',
        address: '',
        zip: 200333
      }, {
        date: '2016-05-03',
        name: '',
        province: '',
        city: '',
        address: '',
        zip: 200333
      }, {
        date: '2016-05-03',
        name: '',
        province: '',
        city: '',
        address: '',
        zip: 200333
      }, {
        date: '2016-05-03',
        name: '',
        province: '',
        city: '',
        address: '',
        zip: 200333
      }, {
        date: '2016-05-03',
        name: '',
        province: '',
        city: '',
        address: '',
        zip: 200333
      }],
      options: [{
        value: '',
        label: ''
      }],
      value: '检验报告单',
      switchIndex: 0
    }
  },
  methods: {
    switchClick (index) {
      this.switchIndex = index
    }
  }
}
</script>

<style lang="less" scoped>
.tab-content {
  width: 100%;
  height: 100%;

  .switch-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    border-bottom: 1px solid #A1A6BB;
    color: #3E3E3E;
    font-size: 18px;
  }

  .pagination {
    display: flex;
    width: 100%;
    height: 100%;

    .pagination-scroll {
      overflow-y: scroll;
      width: 300px;
      height: 100%;
      border-right: 1px solid #A1A6BB;
      box-sizing: border-box;

      &::-webkit-scrollbar {
        width: 0;
      }

      .cell {
        display: flex;
        align-items: center;
        width: 90%;
        height: 54px;
        margin: 0 auto;
        margin-top: 10px;
        padding-left: 20px;
        border-radius: 6px;
        background-color: #D9D9D9;
        box-sizing: border-box;
        color: #333333;
        font-size: 14px;
        cursor: pointer;

        .img {
          width: 30px;
          height: 30px;
          margin-right: 10px;
        }
      }

      .cell1 {
        background-color: #79B3E7;
        color: #1269B7;
      }
    }

    .pagination-table {
      display: flex;
      width: calc(100% - 310px);
      height: 100%;
      margin-left: 10px;

      .box-scroll {
        overflow-y: scroll;
        width: 212px;
        height: 100%;
        padding: 0 20px;
        box-sizing: border-box;

        &::-webkit-scrollbar {
          width: 0;
        }

        .box-title {
          position: sticky;
          top: 0;
          width: 100%;
          padding: 10px 0;
          background-color: #fff;
          text-align: center;
          color: #3E3E3E;
          font-size: 14px;
        }

        .box-list {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          height: 192px;
          margin-bottom: 10px;
          padding: 10px 9px;
          border: 1px solid #666666;

          .table {
            width: calc(100% - 20px);
            height: 68px;
            margin-top: 30px;
            background-color: #666;
          }

          .scalc {
            transform: scale(0.2);
          }

          .number {
            color: #666666;
            font-size: 12px;
          }
        }
      }

      .box-table {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: calc(100% - 192px);
        height: 100%;

        .table-title {
          margin-top: 9px;
          color: #3E3E3E;
          font-size: 20px;
          font-weight: 800;
        }

        .table-line {
          margin-top: 10px;
          color: #3E3E3E;
          font-size: 18px;
          font-weight: 800px;
          letter-spacing: 10px;
        }

        .table-info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          width: 100%;
          padding-bottom: 6px;
          border-bottom: 1px solid #707070;
          color: #3E3E3E;
          font-size: 16px;
          font-weight: 800px;
        }

        .el-table {
          margin-top: 10px;
        }

        ::v-deep .el-table thead.is-group th.el-table__cell,
        ::v-deep .el-table--border,
        .el-table--group {
          border: 1px solid #707070;
        }

        ::v-deep .el-table td.el-table__cell {
          border: 1px solid #707070;
          color: #3E3E3E;
          text-align: center;
        }

        ::v-deep .el-table__row {
          border-bottom: 1px solid #707070;
        }

        ::v-deep.el-table__body-wrapper {
          &::-webkit-scrollbar {
            // 整个滚动条
            width: 0; // 纵向滚动条的宽度
            background: rgba(213, 215, 220, 0.3);
            border: none;
          }

          &::-webkit-scrollbar-track {
            // 滚动条轨道
            border: none;
          }
        }
        ::v-deep.el-table th.gutter {
          display: none;
          width: 0
        }

        ::v-deep.el-table colgroup col[name='gutter'] {
          display: none;
          width: 0;
        }

        ::v-deep.el-table__body {
          width: 100% !important;
        }
      }

    }
  }
}</style>
