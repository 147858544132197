<template>
  <div class="pages">
    <el-input placeholder="搜索" suffix-icon="el-icon-search" v-model="ssnr">
    </el-input>
    <div class="content">
      <div class="content-left">
        <div class="tab">
          <span class="item" v-for="(item, index) in tabs" :key="index" @click="tabClick(index)"
            :class="{ span: tabIndex === index }">{{ item }}</span>
        </div>
        <div class="scroll">
          <div class="item" v-for="(item, index) in 2" :key="index" :class="{ item1: scrollIndex === index }"
            @click="scrollClick(index)">
            <img class="user" src="../../img/emr/user.png" alt="头像">
            <div class="info">
              <el-button size="mini">T-4床</el-button>
              <span class="span" :class="{ span1: scrollIndex === index }">李明明</span>
              <span class="span" :class="{ span1: scrollIndex === index }">02-27</span>
              <span class="span" :class="{ span1: scrollIndex === index }">ll护理</span>
              <span class="span" :class="{ span2: scrollIndex === index }">44岁</span>
              <span class="span" :class="{ span2: scrollIndex === index }">李明明</span>
            </div>
          </div>
        </div>
      </div>
      <div class="content-center">
        <div class="head">
          <img class="user" src="../../img/emr/user.png" alt="头像">
          <el-button size="mini" class="btn1">T-4床</el-button>
          <span class="span">李明明</span>
          <span class="span">02-27</span>
          <span class="span">ll护理</span>
          <span class="span">44岁</span>
          <el-button size="small" class="btn2">居民医保</el-button>
          <el-button size="small" class="btn2 btn3">诊断:慢性鼻炎/慢性咽炎</el-button>
          <el-button size="small" class="btn2 btn4">慢:高血压/糖尿病</el-button>
          <el-button size="small" class="btn2 btn5">敏</el-button>
          <el-button size="small" class="btn2 btn6">术后</el-button>
        </div>
        <div class="box">
          <div class="list">
            <div class="cell" v-for="(item, index) in 2" :key="index">
              <div class="cell-item" :class="{ 'cell-item1': cellIndex === index }" @click="selectCell(index)">
                <img class="img" src="../../img/emr/emr4.png" alt="入" v-if="(cellIndex === index)">
                <img class="img" src="../../img/emr/emr5.png" alt="入" v-else>
                <div class="info" :class="{ info1: cellIndex === index }">
                  <span class="span">2021-05-22 08:55</span>
                  <span class="span size size1">入院病历记录</span>
                  <span class="span size">住院中</span>
                </div>
              </div>
              <div class="cell-box" v-if="(cellIndex === index)">
                <div class="file" v-for="(item, index) in cellChildList" :key="index" @click="cellChildClick(index)">
                  <div class="left"></div>
                  <div class="right" :class="{ right2: cellChildIndex === index }">
                    <img class="img" src="../../img/emr/file1.png" alt="文件">
                    <span>{{ item }}</span>
                  </div>
                </div>
                <div class="file file1">
                  <div class="left left1"></div>
                  <div class="right right1">
                    <img class="img" src="../../img/emr/file1.png" alt="文件">
                    <span>新建文件夹</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 病例概览 -->
          <div class="time" v-if="(showInfo && cellChildIndex === -1)">
            <div class="time-title">
              <span>病例概览</span>
              <el-button @click="openPopover" size="mini" slot="reference">新建病历</el-button>
            </div>
            <div class="item-box">
              <div class="popover" v-if="showPopover">
                <div class="time-right" v-for="(item, index) in cellChildList" :key="index">
                  <img class="img" src="../../img/emr/file1.png" alt="文件">
                  <span>{{ item }}</span>
                </div>
              </div>
              <!-- 电子病历 -->
              <el-timeline>
                <el-timeline-item timestamp="[本次住院] 2021-05-22   08:55" placement="top">
                  <el-card>
                    <div class="card-info">
                      <span>健康无忧人民医院 五官科病房 张小光医师</span>
                    </div>
                    <div class="card-title">
                      <span>住院诊断</span>
                    </div>
                    <div class="card-box">
                      <el-tag type="warning" effect="dark">
                        牙齿敏感证
                      </el-tag>
                      <el-tag type="warning" effect="dark">
                        深龋伴牙髓充血
                      </el-tag>
                      <el-tag type="warning" effect="dark">
                        航空性牙痛
                      </el-tag>
                    </div>
                    <div class="card-title">
                      <span>住院病历</span>
                    </div>
                    <div class="card-info">
                      <span>入院病历 [1]</span>
                    </div>
                    <div class="card-box margin-bottom">
                      <img class="card-img">
                    </div>
                    <div class="card-info">
                      <span>首程病历 [6]</span>
                    </div>
                    <div class="card-box margin-bottom">
                      <img class="card-img">
                    </div>
                    <div class="card-info">
                      <span>首程病历 [6]</span>
                    </div>
                    <div class="card-box margin-bottom">
                      <img class="card-img">
                    </div>
                    <div class="card-info">
                      <span>首程病历 [6]</span>
                    </div>
                    <div class="card-box margin-bottom">
                      <img class="card-img">
                    </div>
                  </el-card>
                </el-timeline-item>
                <el-timeline-item></el-timeline-item>
              </el-timeline>
            </div>
            <!-- 新增入院病历 -->
            <div class="item-record" v-if="(cellChildIndex === 0)">
            </div>
          </div>
          <!-- 入院记录 -->
          <div class="time" v-if="(cellChildIndex === 0)">
            <div class="time-title">
              <span>入院记录</span>
              <el-button @click="openPopover" size="mini" slot="reference">新建病历</el-button>
            </div>
            <div class="item-box">
              <div class="popover" v-if="showPopover">
                <div class="time-right" v-for="(item, index) in cellChildList" :key="index" @click="openMedicalRecord">
                  <img class="img" src="../../img/emr/file1.png" alt="文件">
                  <span>{{ item }}</span>
                </div>
              </div>
              <div class="upload">
                <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/"
                  :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                  <span>新增入院记录</span>
                </el-upload>
                <div class="see-img">
                  <img src="../../img/emr/emr2.png">
                  <div class="see-tip">
                    <span>入院记录 </span>
                    <span>2021-05-55</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 病程记录 -->
          <div class="time" v-else-if="(cellChildIndex === 1)">
            <div class="time-title">
              <span>病程记录</span>
              <el-button @click="openPopover" size="mini" slot="reference">新建病历</el-button>
            </div>
            <div class="item-box">
              <div class="popover" v-if="showPopover">
                <div class="time-right" v-for="(item, index) in cellChildList" :key="index" @click="openCourseDisease">
                  <img class="img" src="../../img/emr/file1.png" alt="文件">
                  <span>{{ item }}</span>
                </div>
              </div>
              <div class="upload">
                <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/"
                  :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                  <span>新增病程记录</span>
                </el-upload>
                <div class="see-img">
                  <img src="../../img/emr/emr2.png">
                  <div class="see-tip">
                    <span>入院记录 </span>
                    <span>2021-05-55</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 新增入院病历 -->
          <MedicalRecord v-if="showMedicalRecord" @close="closeMedicalRecord"></MedicalRecord>
          <!-- 新增病程记录 -->
          <CourseDisease v-if="showCourseDisease" @close="closeCourseDisease"></CourseDisease>
        </div>
      </div>
      <div class="content-right">
        <div class="item">
          <img src="../../img/emr/emr2.png" alt="特殊符号" class="img">
          <span>特殊符号</span>
        </div>
        <div class="item">
          <img src="../../img/emr/emr1.png" alt="病历模板" class="img">
          <span>病历模板</span>
        </div>
        <div class="item">
          <img src="../../img/emr/emr3.png" alt="疾病上报" class="img">
          <span>疾病上报</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MedicalRecord from './medicalRecord.vue'
import CourseDisease from './courseDisease.vue'
export default {
  components: {
    MedicalRecord,
    CourseDisease
  },
  data () {
    return {
      tabs: Object.freeze(['在院', '出院', '归档']),
      tabIndex: 0,
      ssnr: '',
      scrolls: [],
      scrollIndex: 0,
      listIndex: 0,
      cellIndex: -1,
      cellChildIndex: -1,
      cellChildList: Object.freeze(['入院病历', '病程记录', '出院记录', '手术记录', '其他记录']),
      showPopover: false,
      dialogImageUrl: '',
      dialogVisible: false,
      imageUrl: '../../img/emr/emr2.png',
      showInfo: false,
      showMedicalRecord: false,
      showCourseDisease: false
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    scrollClick (index) {
      this.scrollIndex = index
    },
    selectCell (index) {
      this.cellChildIndex = -1
      this.cellIndex = index
      this.showInfo = true
    },
    cellChildClick (index) {
      this.showInfo = false
      this.cellChildIndex = index
    },
    openPopover () {
      this.showPopover = !this.showPopover
    },
    openMedicalRecord () {
      this.showMedicalRecord = true
      this.showPopover = false
    },
    closeMedicalRecord () {
      this.showMedicalRecord = false
    },
    openCourseDisease () {
      this.showCourseDisease = true
    },
    closeCourseDisease () {
      this.showCourseDisease = false
    },
    handleRemove (file, fileList) {
      // console.log(file, fileList)
    },
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    }
  }
}
</script>

<style lang="less" scoped>
.pages {
  width: 100%;
  height: 100%;
  padding: 12px;
  box-sizing: border-box;

  .el-input {
    width: 246px;
  }

  ::v-deep.el-input__inner {
    // background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
    border-radius: 6px;
    border: 1px solid #1F72D3;
  }

  .content {
    display: flex;
    width: 100%;
    height: calc(100% - 70px);
    padding-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 11px;
    background-color: #fff;
    box-sizing: border-box;

    .content-left {
      width: 304px;
      height: 100%;

      .tab {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 45px;
        border-bottom: 1px solid #A1A6BB;
        box-sizing: border-box;
        color: #333333;
        font-size: 16px;

        .item {
          width: 33%;
          text-align: center;
          cursor: pointer;
        }

        .span {
          color: #1269B7;
          font-size: 18px;
          font-weight: bold;
        }
      }

      .scroll {
        overflow-y: scroll;
        width: 100%;
        height: calc(100% - 46px);
        border-left: 1px solid #A1A6BB;
        box-sizing: border-box;

        &::-webkit-scrollbar {
          width: 0;
        }

        .item {
          display: flex;
          align-items: center;
          justify-content: space-around;
          width: 100%;
          height: 80px;
          margin-top: 10px;
          padding: 10px 0;
          background-color: #939393;
          // box-sizing: border-box;
          cursor: pointer;

          .user {
            width: 62px;
            height: 62px;
            margin-left: 5px;
          }

          .info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-right: 5px;

            .el-button {
              margin-left: 15px;
              background-color: #FF9200;
              border: none;
              border-radius: 17px;
              color: #fff;
              font-size: 16px;
            }

            .span {
              margin-left: 15px;
              color: #fff;
              font-size: 14px;
            }

            .span1 {
              color: #333333;
            }

            .span2 {
              color: #A1A6BB;
            }
          }
        }

        .item1 {
          background-color: #1269B7;
        }
      }
    }

    .content-center {
      width: calc(100% - 413px);
      height: 100%;
      border-left: 1px solid #A1A6BB;
      border-right: 1px solid #A1A6BB;
      box-sizing: border-box;

      .head {
        display: flex;
        align-items: center;
        height: 45px;
        padding: 0 7px;
        border-bottom: 1px solid #A1A6BB;
        box-sizing: border-box;

        .user {
          width: 35px;
          height: 35px;
        }

        .btn1 {
          margin-left: 15px;
          background-color: #FF9200;
          border: none;
          border-radius: 17px;
          color: #fff;
          font-size: 16px;
        }

        .span {
          margin-left: 15px;
          color: #333333;
          font-size: 12px;
        }

        .btn2 {
          margin-left: 20px;
          background-color: #fff;
          border: 1px solid #1269B7;
          border-radius: 0;
          color: #1269B7;
          font-size: 12px;
        }

        .btn3 {
          background-color: #1269B7;
          color: #fff;
        }

        .btn4 {
          background-color: #B74412;
          border: 1px solid #B74412;
          color: #fff;
        }

        .btn5 {
          background-color: #333333;
          border: 1px solid #333333;
          color: #fff;
        }

        .btn6 {
          background-color: #C26B09;
          border: 1px solid #C26B09;
          color: #fff;
        }
      }

      .box {
        position: relative;
        display: flex;
        width: 100%;
        height: calc(100% - 45px);
        padding-top: 5px;
        padding-left: 5px;
        box-sizing: border-box;

        .list {
          overflow-y: scroll;
          width: 216px;
          min-width: 288px;
          height: 100%;
          padding-right: 5px;
          border-right: 1px solid #A1A6BB;
          box-sizing: border-box;

          &::-webkit-scrollbar {
            width: 0;
          }

          .cell {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 10px;
            cursor: pointer;
          }

          .cell-item {
            display: flex;
            align-items: center;
            width: 100%;
            height: 54px;
            padding: 0 8px;
            background-color: #D9D9D9;
            box-sizing: border-box;

            .img,
            .el-image {
              width: 35px;
              height: 28px;
            }

            .info {
              display: flex;
              align-items: center;
              flex-wrap: wrap;

              .span {
                width: calc(100% - 4px);
                margin-left: 4px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 14px;
              }

              .size {
                width: calc(30% - 4px);
                font-size: 12px;
              }

              .size1 {
                width: calc(70% - 4px);
              }
            }
          }

          .cell-item1 {
            background-color: #79B3E7;

            .info1 {
              color: #fff;
            }
          }

          .cell-box {
            display: flex;
            flex-direction: column;
            width: 100%;

            .file {
              display: flex;
              align-items: center;
              width: 100%;
              height: 64px;
              padding-top: 10px;
              background-color: #fff;
              border-left: 1px dashed #A1A6BB;
              box-sizing: border-box;

              .left {
                width: 28px;
                height: 1px;
                background-color: #A1A6BB;
              }

              .right {
                display: flex;
                align-items: center;
                width: calc(100% - 28px);
                height: 100%;
                padding: 0 10px;
                background-color: #D9D9D9;
                box-sizing: border-box;
                color: #333333;
                font-size: 14px;

                .img {
                  width: 37px;
                  height: 33px;
                  margin-right: 7px;
                }
              }

              .right2 {
                background-color: #79B3E7;
                color: #fff;
              }
            }

            .file1 {
              align-items: flex-start;
              height: 57px;
              padding: 0;
              border: none;

              .left1 {
                height: 30px;
                background-color: #fff;
                border-left: 1px dashed #A1A6BB;
                border-bottom: 1px solid #A1A6BB;
                box-sizing: border-box;
              }

              .right1 {
                height: calc(100% - 10px);
                margin-top: 10px;

                .img {
                  width: 29px;
                  height: 25px;
                }
              }
            }
          }
        }

        .time {
          position: relative;
          width: calc(100% - 288px);
          height: 100%;

          .time-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 39px;
            line-height: 39px;
            padding: 0 33px;
            border-bottom: 1px solid #A1A6BB;
            box-sizing: border-box;
            color: #333333;
            font-size: 14px;

            .el-button {
              background-color: #1269B7;
              color: #fff;
              border: none;
            }
          }

          .item-box {
            position: relative;
            width: 100%;
            height: 100%;
            padding: 10px;
            box-sizing: border-box;

            ::v-deep.el-timeline {
              overflow-y: scroll;
              width: 100%;
              height: calc(100% - 40px);

              &::-webkit-scrollbar {
                width: 0;
              }
            }

            .popover {
              position: absolute;
              top: 0;
              right: 0;
              z-index: 8888;
              display: flex;
              flex-direction: column;
              padding: 0 10px;
              padding-top: 10px;
              background-color: #fff;
              box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1608);

              .time-right {
                display: flex;
                align-items: center;
                width: 100%;
                height: 54px;
                margin-bottom: 10px;
                padding: 0 10px;
                background-color: #D9D9D9;
                box-sizing: border-box;
                color: #333333;
                font-size: 14px;

                .img {
                  width: 37px;
                  height: 33px;
                  margin-right: 7px;
                }
              }
            }

            ::v-deep .el-timeline-item__timestamp {
              color: #FF9200;
              font-size: 14px;
            }

            ::v-deep .el-timeline-item__node {
              background-color: #FF9200;
            }

            ::v-deep .el-timeline-item__tail {
              top: 12px;
              border-left: 2px #FF9200 dashed;
            }

            .el-card {
              padding: 0;
              border: none;
              box-shadow: none;

              ::v-deep .el-card__body {
                display: flex;
                flex-direction: column;
                padding: 0;
              }

              .card-info {
                color: #A1A6BB;
                font-size: 12px;
              }

              .card-title {
                margin-top: 26px;
                color: #FF9200;
                font-size: 12px;
              }

              .card-box {
                display: flex;
                align-items: center;
                margin-top: 6px;

                ::v-deep .el-tag--dark.el-tag--warning {
                  margin-right: 10px;
                  background-color: #FF9200;
                  border-radius: 8px;
                }

                .card-img {
                  width: 116px;
                  height: 130px;
                  background-color: #333333;
                }
              }

              .margin-bottom {
                margin-bottom: 30px;
              }
            }

            .upload {
              display: flex;
              width: 100%;
              height: calc(100% - 40px);

              ::v-deep.el-upload {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 116px;
                height: 130px;
              }

              // ::v-deep .el-upload--picture-card {
              //     line-height: normal;
              //   }

              // ::v-deep .el-dialog {
              //   position: relative;

              //   .dialog-info {
              //     position: absolute;
              //     left: 0;
              //     bottom: 0;
              //     width: 100%;
              //   }
              // }

              ::v-deep.el-upload {
                border: 1px solid #CACACA;
                border-radius: 6px;
                cursor: pointer;
                position: relative;
                overflow: hidden;
                color: #CACACA;
                font-size: 13px;
              }

              .avatar-uploader .el-upload:hover {
                border-color: #409EFF;
              }

              .avatar-uploader-icon {
                font-size: 28px;
                color: #CACACA;
                width: 178px;
                text-align: center;
              }

              .avatar {
                width: 116px;
                height: 130px;
                display: block;
              }

              .see-img {
                position: relative;
                width: 116px;
                height: 130px;
                margin-left: 10px;
                background-color: #333333;

                .see-tip {
                  position: absolute;
                  left: 0;
                  bottom: 0;
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                  padding: 3px 0;
                  background-color: rgba(0, 0, 0, .69);
                  color: #fff;
                  font-size: 12px;

                  span {
                    margin-left: 6px;
                  }
                }
              }
            }
          }

          .item-record {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;
            height: calc(100% - 50px);
          }
        }
      }
    }

    .content-right {
      width: 109px;
      height: 100%;

      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
        color: #333333;
        font-size: 14px;

        .img {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}
</style>
