var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab-content"},[_vm._m(0),_c('div',{staticClass:"pagination"},[_c('div',{staticClass:"pagination-scroll"},[_c('div',{staticClass:"date"},[_vm._m(1),_vm._m(2),_vm._m(3),_c('el-select',{attrs:{"placeholder":"请选择","size":"small"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('div',{staticClass:"date-scroll"},[_c('div',{staticClass:"date-box"},[_vm._l((20),function(item,index){return _c('div',{key:index,staticClass:"date-list",on:{"click":function($event){return _vm.switchClick(index)}}},[_c('div',{staticClass:"left"}),_c('div',{staticClass:"center",class:{ center1: _vm.switchIndex === index }}),_c('div',{staticClass:"right",class:{ right1: _vm.switchIndex === index }},[_c('div',{staticClass:"blue",class:{ blue1: _vm.switchIndex === index }},[_c('span',[_vm._v("新型冠状病毒核酸检测 ")])]),_vm._m(4,true)])])}),_c('div',{staticClass:"position"}),_c('div',{staticClass:"position1"})],2)])]),_c('div',{staticClass:"pagination-table"},[_vm._m(5),_c('el-table',{attrs:{"data":_vm.tableData,"header-cell-style":{ background: '#D4D4D4', 'text-align': 'center', color: '#3E3E3E' }}},[_c('el-table-column',{attrs:{"prop":"province","label":"项目名称"}}),_c('el-table-column',{attrs:{"prop":"city","label":"英文标识"}}),_c('el-table-column',{attrs:{"prop":"address","label":"结果"}}),_c('el-table-column',{attrs:{"prop":"zip","label":"参考范围"}}),_c('el-table-column',{attrs:{"prop":"zip","label":"单位"}})],1)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"switch-title"},[_c('span',[_vm._v("住院报告单")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"date-item"},[_c('span',[_vm._v("今日")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"date-item"},[_c('span',[_vm._v("近7日")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"date-item"},[_c('span',[_vm._v("近3月")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom"},[_c('span',{staticClass:"time"},[_vm._v("2021-05-22 08:55 ")]),_c('span',[_vm._v("未出报告")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-info"},[_c('span',[_vm._v("检验项目：新型冠状病毒核酸检测")]),_c('span',[_vm._v("申请科室：五官科门诊")]),_c('span',[_vm._v("医生：叶谓之")]),_c('span',[_vm._v("送检时间：2021-05-22 08:55")]),_c('span',[_vm._v("样本号：15")]),_c('span',[_vm._v("样本类型：鼻咽拭子")]),_c('span',[_vm._v("检验：宋叶月")]),_c('span',[_vm._v("审核：王爱护")]),_c('span',[_vm._v("报告时间：2021-05-22 08:55")])])
}]

export { render, staticRenderFns }