<template>
  <div class="popup">
    <div class="popup-box">
      <div class="popup-head">
        <div class="btns">
          <el-button class="color">全部</el-button>
          <el-button>医技检查</el-button>
          <el-button>医嘱</el-button>
        </div>
        <div class="popup-input">
          <el-input placeholder="开医嘱/检验/检查申请单"></el-input>
          <div class="right">
            <span>回车保存</span>
            <span class="icon">↵</span>
          </div>
        </div>
        <div class="close" @click="close">
          <span class="el-icon-close"></span>
        </div>
      </div>
      <div class="popup-scroll">
        <div class="scroll-item">
          <div class="item-title">
            <div class="left">
              <el-button class="button-color">医嘱</el-button>
              <el-button size="small">西药</el-button>
              <el-button size="small">慢性咽炎</el-button>
              <el-button size="small">慢性鼻炎</el-button>
            </div>
            <div class="right">
              <el-button size="small" class="button-color1">复制</el-button>
              <el-button size="small" class="button-color1">存为模板</el-button>
              <el-button size="small" class="button-color1">打印</el-button>
              <el-button size="small" class="button-color1">签名</el-button>
            </div>
          </div>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="0">
              <div><span>自备</span></div>
              <el-row :gutter="3">
                <el-col :span="9">
                  <div class="col col1">
                    <div class="block">
                      <span>苯磺酸氨氯地平(进口)</span>
                    </div>
                    <div class="grey">
                      <span>[001201000030000-ACAC0001] 2021-05-22 08:55</span>
                    </div>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="col col2">
                    <div class="block">
                      <span>5mg*7片/盒</span>
                    </div>
                  </div>
                </el-col>
                <el-col :span="2">
                  <div class="col col2">
                    <el-input placeholder="设定剂量" size="small"></el-input>
                  </div>
                </el-col>
                <el-col :span="2">
                  <div class="col col2">
                    <el-input placeholder="设定用法" size="small"></el-input>
                  </div>
                </el-col>
                <el-col :span="2">
                  <div class="col col2">
                    <el-input placeholder="设定频次" size="small"></el-input>
                  </div>
                </el-col>
                <el-col :span="2">
                  <div class="col col2">
                    <el-input placeholder="设定天数" size="small"></el-input>
                    <div>
                      <span>日</span>
                    </div>
                  </div>
                </el-col>
                <el-col :span="2">
                  <div class="col col2">
                    <el-input placeholder="数量" size="small" class="border-none"></el-input>
                  </div>
                </el-col>
                <el-col :span="2">
                  <div class="col col1">
                    <div class="block">
                      <span>单: 0.00</span>
                    </div>
                    <div class="block">
                      <span>总: 0.00</span>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </el-checkbox>
            <el-checkbox label="1">
              <div><span>自备</span></div>
              <el-row :gutter="3">
                <el-col :span="9">
                  <div class="col col1">
                    <div class="block">
                      <span>苯磺酸氨氯地平(进口)</span>
                    </div>
                    <div class="grey">
                      <span>[001201000030000-ACAC0001] 2021-05-22 08:55</span>
                    </div>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="col col2">
                    <div class="block">
                      <span>5mg*7片/盒</span>
                    </div>
                  </div>
                </el-col>
                <el-col :span="2">
                  <div class="col col2">
                    <el-input placeholder="设定剂量" size="small"></el-input>
                  </div>
                </el-col>
                <el-col :span="2">
                  <div class="col col2">
                    <el-input placeholder="设定用法" size="small"></el-input>
                  </div>
                </el-col>
                <el-col :span="2">
                  <div class="col col2">
                    <el-input placeholder="设定频次" size="small"></el-input>
                  </div>
                </el-col>
                <el-col :span="2">
                  <div class="col col2">
                    <el-input placeholder="设定天数" size="small"></el-input>
                    <div>
                      <span>日</span>
                    </div>
                  </div>
                </el-col>
                <el-col :span="2">
                  <div class="col col2">
                    <el-input placeholder="数量" size="small" class="border-none"></el-input>
                  </div>
                </el-col>
                <el-col :span="2">
                  <div class="col col1">
                    <div class="block">
                      <span>单: 0.00</span>
                    </div>
                    <div class="block">
                      <span>总: 0.00</span>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </el-checkbox>
          </el-checkbox-group>
          <div class="remarks">
            <div class="key">
              <span>医嘱备注</span>
            </div>
            <div class="input">
              <el-input placeholder="请输入备注"></el-input>
            </div>
          </div>
        </div>
        <div class="scroll-item">
          <div class="item-title">
            <div class="left">
              <el-button class="button-color button-green">医嘱</el-button>
              <el-button size="small">西药</el-button>
              <el-button size="small">慢性咽炎</el-button>
              <el-button size="small">慢性鼻炎</el-button>
            </div>
            <div class="right">
              <el-button size="small" class="button-color1">复制</el-button>
              <el-button size="small" class="button-color1">存为模板</el-button>
              <el-button size="small" class="button-color1">打印</el-button>
              <el-button size="small" class="button-color1">签名</el-button>
            </div>
          </div>
          <div class="item-list">
            <el-row :gutter="3">
                <el-col :span="1">
                  <div class="col col2">
                    <div class="block">
                      <span>检验</span>
                    </div>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="col col1">
                    <div class="block">
                      <span>血常规(级五分类)</span>
                    </div>
                    <div class="grey">
                      <span>[自费] 2021-05-22 08:55</span>
                    </div>
                  </div>
                </el-col>
                <el-col :span="2">
                  <div class="col col2">
                    <el-input placeholder="全血" size="small" class="border-none"></el-input>
                  </div>
                </el-col>
                <el-col :span="2">
                  <div class="col col2">
                    <el-input placeholder="100% 上传" size="small" class="border-none"></el-input>
                  </div>
                </el-col>
                <el-col :span="2">
                  <div class="col col2">
                    <div class="block">
                      <span>检验科</span>
                    </div>
                  </div>
                </el-col>
                <el-col :span="2">
                  <div class="col col1">
                    <div class="block">
                      <span>单: 0.00</span>
                    </div>
                    <div class="block">
                      <span>总: 0.00</span>
                    </div>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="col col2">
                    <div class="blue">
                      <span>已签名-未计费</span>
                    </div>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="col col2">
                    <div class="block">
                      <span>暂无备注</span>
                    </div>
                  </div>
                </el-col>

            </el-row>
          </div>
          <div class="item-list">
            <el-row :gutter="3">
                <el-col :span="1">
                  <div class="col col2">
                    <div class="block">
                      <span>检验</span>
                    </div>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="col col1">
                    <div class="block">
                      <span>血常规(级五分类)</span>
                    </div>
                    <div class="grey">
                      <span>[自费] 2021-05-22 08:55</span>
                    </div>
                  </div>
                </el-col>
                <el-col :span="2">
                  <div class="col col2">
                    <el-input placeholder="全血" size="small" class="border-none"></el-input>
                  </div>
                </el-col>
                <el-col :span="2">
                  <div class="col col2">
                    <el-input placeholder="100% 上传" size="small" class="border-none"></el-input>
                  </div>
                </el-col>
                <el-col :span="2">
                  <div class="col col2">
                    <div class="block">
                      <span>检验科</span>
                    </div>
                  </div>
                </el-col>
                <el-col :span="2">
                  <div class="col col1">
                    <div class="block">
                      <span>单: 0.00</span>
                    </div>
                    <div class="block">
                      <span>总: 0.00</span>
                    </div>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="col col2">
                    <div class="blue">
                      <span>已签名-未计费</span>
                    </div>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="col col2">
                    <div class="block">
                      <span>暂无备注</span>
                    </div>
                  </div>
                </el-col>

            </el-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      checkList: []
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.popup {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .85);

  .popup-box {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 37px);
    background-color: #fff;
    border-radius: 25px 25px 0 0;

    .popup-head {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: calc(100% - 50px);
      margin: 0 auto;
      padding: 10px 0;
      border-bottom: 1px solid #A1A6BB;

      .btns {

        .el-button {
          border: 1px solid #1269B7;
          border-radius: 0;
          color: #1269B7;
        }

        .color {
          background-color: #1269B7;
          color: #fff;
        }
      }

      .popup-input {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 700px;
        padding-right: 10px;
        border-radius: 7px;
        border: 1px solid #A1A6BB;
        box-sizing: border-box;

        .el-input {
          width: calc(100% - 200px);

          ::v-deep .el-input__inner {
            border: none;
          }
        }

        .right {
          padding: 10px 0;
          color: #A1A6BB;
          font-size: 12px;

          .icon {
            margin-left: 8px;
            font-size: 16px;
          }
        }
      }

      .close {
        color: #A1A6BB;
        font-size: 30px;
      }
    }

    .popup-scroll {
      overflow-y: scroll;
      width: 100%;
      height: calc(100% - 90px);

      &::-webkit-scrollbar {
        width: 0;
      }

      .scroll-item {
        display: flex;
        flex-direction: column;
        width: calc(100% - 10px);
        margin: 0 auto;
        margin-top: 10px;
        padding: 13px;
        padding-top: 0;
        padding-left: 0;
        background-color: #D4D2D2;
        box-sizing: border-box;

        .item-title {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .left,
          .right {
            display: flex;
            align-items: center;

            .el-button {
              border-color: #1269B7;
              border-radius: 0;
              background-color: #D4D2D2;
              color: #1269B7;
            }

            .button-color {
              background-color: #1269B7;
              color: #fff;
            }

            .button-color1 {
              background-color: #1286EE;
              color: #fff;
              border-color: #1286EE;
            }

            .button-green {
              background-color: #10BE61;
              border: none;
            }
          }
        }

        .el-checkbox-group {
          display: flex;
          flex-direction: column;
          margin-top: 12px;

          .el-checkbox {
            display: flex;
            align-items: center;
            width: 100%;
            margin-bottom: 4px;
            padding-left: 10px;
            box-sizing: border-box;
          }

          ::v-deep .el-checkbox__label {
            display: flex;
            align-items: center;
            width: 100%;
          }

          .el-row {
            width: calc(100% - 8px);
            margin-left: 8px;
          }

          .col {
            width: 100%;
            height: 40px;
            background-color: #FFFFFF;
            font-size: 12px;

            .block {
              color: #333;
            }

            .grey {
              color: #A1A6BB;
            }

            .block,
            .grey {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 100%;
            }

            .el-input {
              width: calc(100% - 4px);
              height: calc(100% - 4px);
              border: 1px solid #1286EE;
              box-sizing: border-box;

              ::v-deep .el-input__inner {
                width: 100%;
                border: none;
                font-size: 14px;
                padding: 2px;
                text-align: center;
              }
            }

            .border-none {
              border: none;
            }
          }

          .col1 {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 0 10px;
            box-sizing: border-box;
          }

          .col2 {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
          }
        }

        .remarks {
          display: flex;
          align-items: center;
          width: 100%;
          padding-left: 10px;
          box-sizing: border-box;

          .key {
            margin-right: 2px;
            color: #333333;
            font-size: 12px;
          }

          .input {
            padding: 2px 4px;
            background-color: #fff;

            ::v-deep .el-input__inner {
              width: 600px;
              border-color: #1286EE;
              border-radius: 0;
            }
          }
        }

        .item-list {
          display: flex;
          align-items: center;
          width: 100%;
          margin-top: 10px;
          padding-left: 10px;
          box-sizing: border-box;

          .el-row {
            width: calc(100% - 8px);
            margin-left: 8px;
          }

          .col {
            width: 100%;
            height: 40px;
            background-color: #FFFFFF;
            font-size: 12px;

            .block {
              color: #333;
            }

            .grey {
              color: #A1A6BB;
            }

            .block,
            .grey {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 100%;
            }

            .el-input {
              width: calc(100% - 4px);
              height: calc(100% - 4px);
              border: 1px solid #1286EE;
              box-sizing: border-box;

              ::v-deep .el-input__inner {
                width: 100%;
                border: none;
                font-size: 14px;
                padding: 2px;
                text-align: center;
              }
            }

            .border-none {
              border: none;
            }

            .blue {
              color: #1269B7;
              font-size: 14px;
            }
          }

          .col1 {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 0 10px;
            box-sizing: border-box;
          }

          .col2 {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
          }
        }
      }
    }
  }
}</style>
