<template>
  <div class="tabBox">
    <div class="tabBox-search">
      <el-input placeholder="搜索" suffix-icon="el-icon-search"></el-input>
    </div>
    <div class="tabBox-box">
      <div class="tabBox-left">
        <div class="title">
          <el-button size="small" @click="close">返回</el-button>
          <div class="text">
            <span>患者详情</span>
          </div>
        </div>
        <div class="info-scroll">
          <div class="col-line">
          <img class="image" src="../../img/emr/user.png">
          <div class="info">
            <div class="yellow">
              <span>T-4床</span>
            </div>
            <div class="black">
              <span>王丽霞</span>
            </div>
            <div class="black color">
              <span>女</span>
            </div>
            <div class="black">
              <span>44岁3月</span>
            </div>
            <div class="black">
              <span>ll护理</span>
            </div>
            <div class="black">
              <span>202111606032</span>
            </div>
          </div>
        </div>
        <div class="col-line">
          <div class="box">
            <span>居民医保</span>
          </div>
          <div class="time">
            <span>2021-05-25 08:55 [今日入院]</span>
          </div>
        </div>
        <el-descriptions class="margin-top" :column="1" border>
          <el-descriptions-item>
            <template slot="label">
              入院诊断：
            </template>
            1.慢性咽炎 2.慢性鼻炎
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              住院诊断：
            </template>
            [1.慢性咽炎 2.慢性鼻炎]
          </el-descriptions-item>
        </el-descriptions>
        <div class="col-line col-line1">
          <div class="tag">
            <span>ICU</span>
          </div>
          <div class="tag tag-red">
            <span>敏</span>
          </div>
          <div class="tag tag-yellow">
            <span>慢</span>
          </div>
          <div class="tag">
            <span>术</span>
          </div>
          <div class="tag">
            <span>CV</span>
          </div>
          <div class="tag">
            <span>热</span>
          </div>
        </div>
        <div class="col-line col-line2">
          <div class="name">
            <img class="name-img" src="../../img/patientManage/user1.png">
            <span>叶谓之</span>
          </div>
          <div class="name">
            <img class="name-img" src="../../img/patientManage/user2.png">
            <span>叶谓之</span>
          </div>
        </div>
        <el-descriptions class="margin-top" title="其他基本信息" :column="1" border>
          <el-descriptions-item>
            <template slot="label">
              证件类型：
            </template>
            身份证
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              证件号码：
            </template>
            010101197908240090
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              联系方式：
            </template>
            13725650022
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              居住地址：
            </template>
            江南省泵年市天心区花心范街道，华胜
府9号
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              陪护人：
            </template>
            李华兴 (丈夫)
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              联系方式：
            </template>
            13725650055
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              陪护证明：
            </template>
           <span class="font-color1">新型冠状病毒核酸检测</span><span class="font-color2">[良性]</span> 2021-05-25  09:22
          </el-descriptions-item>
        </el-descriptions>
        </div>
      </div>
      <div class="tabBox-right">
        <div class="switch">
          <div class="switch-item" v-for="(item, index) in switchs" :key="index" :class="{ 'switch-item1': switchIndex === index }" @click="switchClick(index)">
            <span>{{ item }}</span>
          </div>
        </div>
        <div class="switch-box">
          <!-- 患者住院概览 医嘱单 -->
          <Tab1 v-if="switchIndex === 0 || switchIndex === 1"></Tab1>
          <!-- 医技报告 -->
          <Tab3 v-if="switchIndex === 2"></Tab3>
          <!-- 护理文书 -->
          <Tab4 v-if="switchIndex === 3"></Tab4>
          <!-- 既往住院记录 -->
          <Tab5 v-if="switchIndex === 4"></Tab5>
          <!-- 疾病上报 -->
          <Tab6 v-if="switchIndex === 5"></Tab6>
          <!-- 健康记录 -->
          <Tab7 v-if="switchIndex === 6"></Tab7>
          <!-- 会诊记录 -->
          <Tab8 v-if="switchIndex === 7"></Tab8>
          <!-- 手术记录 -->
          <Tab9 v-if="switchIndex === 8"></Tab9>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tab1 from './tab1.vue'
import Tab3 from './tab3.vue'
import Tab4 from './tab4.vue'
import Tab5 from './tab5.vue'
import Tab6 from './tab6.vue'
import Tab7 from './tab7.vue'
import Tab8 from './tab8.vue'
import Tab9 from './tab9.vue'
export default {
  components: {
    Tab1,
    Tab3,
    Tab4,
    Tab5,
    Tab6,
    Tab7,
    Tab8,
    Tab9
  },
  data () {
    return {
      switchIndex: 0,
      switchs: Object.freeze(['患者住院概览', '医嘱单', '医技报告', '护理文书', '既往住院记录(3)', '疾病上报(1)', '健康记录(1)', '会诊记录', '手术记录'])
    }
  },
  methods: {
    switchClick (index) {
      this.switchIndex = index
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.tabBox {
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 8888;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  background-color: #fff;
  padding: 10px;
  box-sizing: border-box;

  .tabBox-search {
    padding: 4px 0;

    .el-input {
      width: 246px;
    }
  }

  .tabBox-box {
    display: flex;
    width: 100%;
    height: calc(100% - 60px);
    margin-top: 4px;
    padding: 0 18px;
    background-color: #fff;
    box-sizing: border-box;

    .tabBox-left {
      width: 360px;
      height: 100%;
      border-right: 1px solid #A1A6BB;
      box-sizing: border-box;

      .title {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 4px 0;
        border-bottom: 1px solid #A1A6BB;

        .el-button {
          background-color: #D9D9D9;
          border: none;
          border-radius: 0;
        }

        .text {
          margin-left: 20px;
          color: #333;
          font-size: 18px;
          font-weight: bold;
        }
      }

      .info-scroll {
        overflow-y: scroll;
        width: 100%;
        height: calc(100% - 80px);

        &::-webkit-scrollbar {
          width: 0;
        }
      }

      .col-line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        padding: 10px 0;
        border-bottom: 1px solid #A1A6BB;
        color: #333333;
        font-size: 16px;

        .image {
          width: 50px;
          height: 50px;
        }

        .info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          width: calc(100% - 60px);
          margin-left: 10px;

          .yellow {
            display: block;
            margin-right: 6px;
            padding: 2px 8px;
            background-color: #FF9200;
            border-radius: 17px;
            color: #fff;
            font-size: 17px;
          }

          .black {
            margin-right: 6px;
            color: #333;
            font-size: 16px;
          }

          .color {
            color: #FF00F7;
          }
        }

        .box {
          padding: 6px 10px;
          border: 1px solid #1269B7;
          color: #1269B7;
          font-size: 16px;
        }

        .time {
          color: #333333;
          font-size: 16px;
        }

        .flex {
          display: flex;
          align-items: center;
        }

        .tag {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 33px;
          height: 33px;
          margin-right: 10px;
          padding: 0 4px;
          background-color: #D9D9D9;
          white-space: nowrap;
          color: #666666;
          font-size: 16px;
        }

        .tag1 {
          background-color: #fff;
        }

        .tag-red {
          width: 31px;
          height: 31px;
          background-color: #FFB093;
          border: 1px solid #FF4907;
          color: #FF4907;
        }

        .tag-yellow {
          width: 31px;
          height: 31px;
          background-color: #FFCA83;
          border: 1px solid #FF9200;
          color: #FF9200;
        }

        .time {
          color: #333;
          font-size: 16px;
        }

        .name {
          display: flex;
          align-items: center;
          margin-left: 20px;
          color: #333;
          font-size: 16px;

          .name-img {
            width: 18px;
            height: 18px;
            margin-right: 4px;
          }
        }
      }

      .col-line1 {
        margin: 0;
        border: none;
      }

      .col-line2 {
        justify-content: flex-end;
        margin-top: 0;
      }

      .el-descriptions {
        margin-top: 10px;

        .font-color1 {
          color: #1269B7;
        }

        .font-color2 {
          color: #10BE61;
        }

        ::v-deep .el-descriptions-item__label.is-bordered-label {
          background-color: transparent;
          white-space: nowrap;
          color: #333;
        }
      }

    }

    .tabBox-right {
      width: calc(100% - 360px);
      height: 100%;
      padding: 14px 5px;
      box-sizing: border-box;

      .switch {
        display: flex;
        align-items: center;

        .switch-item {
          margin-right: 10px;
          padding: 8px 20px;
          color: #1269B7;
          font-size: 14px;
          border: 1px solid #1269B7;
          cursor: pointer;
        }

        .switch-item1 {
          background-color: #1269B7;
          color: #fff;
        }
      }

      .switch-box {
        width: 100%;
        height: calc(100% - 80px);
        margin-top: 10px;
      }
    }
  }
}
</style>
