<template>
  <div class="exerciseDiet">
    <div class="diet-left">
      <UserBasicInfo></UserBasicInfo>
    </div>
    <div class="diet-right">
      <div class="tab">
        <div class="item">
          <span class="item-top item-top1"></span>
          <span class="item-bottom item-bottom1">健康干预</span>
        </div>
      </div>
      <div class="diet-box">
        <div class="add">
          <el-button size="mini" type="primary" @click="openAddIntervene">新增</el-button>
        </div>
        <div class="table">
          <el-table :data="tableData" height="100%" border>
            <el-table-column prop="name" label="干预名称"></el-table-column>
            <el-table-column label="干预时间"></el-table-column>
            <el-table-column label="操作" width="240">
              <!-- slot-scope="scope" -->
              <template>
                <!-- <div class="flex">
                  <span @click="openSeeIntervene">查看</span>
                  <span>编辑</span>
                  <span class="red">删除</span>
                </div> -->
                <el-button size="mini" type="primary" @click="openSeeIntervene">查看</el-button>
                <el-button size="mini" type="primary">编辑</el-button>
                <el-button size="mini" type="danger">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <BasicInfoPopup v-if="showBasicInfoPopup" @close="closeBasicInfoPopup"></BasicInfoPopup>
    <!-- 干预详情 -->
    <SeeIntervene v-if="showSeeIntervene" @closePopup="closeSeeIntervene"></SeeIntervene>
    <!-- 新增干预 -->
    <AddIntervene v-if="showAddIntervene" @closePopup="closeAddIntervene"></AddIntervene>
  </div>
</template>

<script>
import BasicInfoPopup from '../../monitoring/basicInfoPopup.vue'
import UserBasicInfo from '../../../../components/user-basic-info/user-basic-info.vue'
import SeeIntervene from './seeIntervene.vue'
import AddIntervene from './addIntervene.vue'
export default {
  components: {
    BasicInfoPopup,
    UserBasicInfo,
    SeeIntervene,
    AddIntervene
  },
  data () {
    return {
      showBasicInfoPopup: false,
      tableData: [
        {
          date: '2020-02-27 17:20',
          name: '2020031',
          type: '1',
          value: false
        }
      ],
      showSeeIntervene: false,
      showAddIntervene: false
    }
  },
  methods: {
    openSeeIntervene () {
      this.showSeeIntervene = true
    },
    closeSeeIntervene () {
      this.showSeeIntervene = false
    },
    openAddIntervene () {
      this.showAddIntervene = true
    },
    closeAddIntervene () {
      this.showAddIntervene = false
    },
    openBasicInfoPopup () {
      this.showBasicInfoPopup = true
    },
    closeBasicInfoPopup () {
      this.showBasicInfoPopup = false
    }
  }
}
</script>

<style lang="less" scoped>
.exerciseDiet {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #fff;

  .diet-left {
    width: 270px;
    height: 99%;
    background-color: #081C38;
    border-radius: 6px;

    .title {
      width: 100%;
      height: 35px;
      line-height: 35px;
      padding-left: 34px;
      padding-top: 5px;
      box-sizing: border-box;
      background: url('../../img/dialogTitleBg.png') no-repeat;
      background-size: 100% 100%;
      color: #fff;
      font-size: 20px;
      font-weight: 600;
    }

    .search {
      display: flex;
      align-items: center;
      margin-top: 10px;

      .el-input,
      ::v-deep .el-input__inner {
        background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
        border: none;
        border-radius: 6px;
        color: #fff;

        &::-webkit-input-placeholder {
          color: #fff;
          font-size: 18px;
        }
      }

      ::v-deep .el-input__suffix {
        color: #fff;
        font-size: 18px;
      }
    }

    .scroll {
      overflow: scroll;
      width: 100%;
      height: calc(100% - 100px);
      margin-top: 5px;
      border-radius: 6px;
      background-color: rgba(29, 64, 112, .33);

      .list {
        display: flex;
        align-items: center;
        width: 610px;
        height: 40px;
        color: #01EEFD;
        font-size: 16px;
        cursor: pointer;

        div {
          width: 60px;
          text-align: center;
        }

        .flex2 {
          width: 100px;
        }

        .flex3 {
          width: 150px;
        }

        .flex4 {
          width: 300px;
        }
      }

      &::-webkit-scrollbar {
        display: none;
      }

      .movetop {
        height: calc(100% - 100px);
      }

      .color {
        position: sticky;
        top: 0;
        left: 0;
        padding: 4px 0;
        z-index: 88;
        background-color: rgba(14, 40, 75, 1);
      }
    }
  }

  .diet-right {
    width: calc(100% - 280px);
    height: 100%;
    margin-left: 10px;

    .tab {
      display: flex;
      width: 100%;
      height: 50px;

      .item {
        display: flex;
        flex-direction: column;
        width: 137px;
        height: 50px;
        margin-right: 40px;
        cursor: pointer;

        .item-top {
          position: relative;
          width: 84px;
          height: 10px;
          background-color: rgba(3, 188, 215, .22);

          &::after {
            position: absolute;
            top: -7px;
            left: -7.2px;
            z-index: 8;
            content: '';
            width: 0;
            height: 0;
            border: 7px solid transparent;
            border-right-color: #fff;
            transform: rotate(45deg);
          }

          &::before {
            position: absolute;
            top: -7px;
            right: -7.2px;
            z-index: 8;
            content: '';
            width: 0;
            height: 0;
            border: 7px solid transparent;
            border-left-color: #fff;
            transform: rotate(-45deg);
          }
        }

        .item-top1 {
          background-color: #03BCD7;
        }

        .item-bottom {
          width: 100%;
          height: 40px;
          line-height: 40px;
          text-align: center;
          color: #fff;
          font-size: 18px;
          background: linear-gradient(107deg, rgba(1, 118, 204, .22) 0%, rgba(2, 86, 151, 0.22) 60%, rgba(2, 86, 151, .22) 60%, rgba(2, 64, 115, .22) 100%);
          box-sizing: border-box;
        }

        .item-bottom1 {
          background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
        }
      }
    }

    .diet-box {
      width: 99%;
      height: calc(100% - 60px);
      margin-top: 10px;
      padding: 10px;
      border: 4px solid rgba(1, 124, 219, .27);
      box-sizing: border-box;

      .add {
        display: flex;
        align-items: center;
        width: 100%;
        height: 46px;
      }

      .table {
        width: 100%;
        height: calc(100% - 100px);
      }

      /deep/.el-table .cell {
        text-align: center;
        color: #000;
      }

      .flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        font-size: 16px;

        span {
          color: #01EEFD;
          cursor: pointer;
        }

        .red {
          color: #FF0000 !important;
        }
      }
    }
  }
}
</style>
