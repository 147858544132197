<template>
  <div class="tab-content">
    <div class="switch-title">
      <div class="switch-left">
        <el-button :class="{ 'button-color': switchIndex === 0 }" @click="switchClick(0)">长期医嘱</el-button>
        <el-button :class="{ 'button-color': switchIndex === 1 }" @click="switchClick(1)">临时医嘱</el-button>
      </div>
      <div class="switch-right">
        <el-input placeholder="输入页码"></el-input>
        <el-button>打印</el-button>
        <el-button>续打</el-button>
        <el-button>打印当前页</el-button>
        <el-button>全部打印</el-button>
      </div>
    </div>
    <div class="pagination">
      <div class="pagination-scroll">
        <div class="pagination-list" v-for="(item, index) in 8" :key="index">
          <div class="table">
          </div>

          <div class="number">第{{ item }}页</div>
        </div>
      </div>
      <div class="pagination-table">
        <div class="table-title">
          <span>健康无忧人民医院</span>
        </div>
        <div class="table-line">
          <span>长期医嘱单</span>
        </div>
        <div class="table-info">
          <span>姓名：[李艳艳]</span>
          <span>年龄：[44岁3月]</span>
          <span>床号：[T-4]</span>
          <span>科室：[五官科]</span>
          <span>病区：[五官科一病区]</span>
          <span>住院号：[202005562008]</span>
        </div>
        <el-table :data="tableData" :header-cell-style="{ background: '#fff', 'text-align': 'center', color: '#3E3E3E' }">
          <el-table-column label="起始">
            <el-table-column prop="province" label="年月日时分">
            </el-table-column>
            <el-table-column prop="city" label="医嘱内容 ">
            </el-table-column>
            <el-table-column prop="address" label="医师签名">
            </el-table-column>
            <el-table-column prop="zip" label="护士签名">
            </el-table-column>
          </el-table-column>
          <el-table-column label="停止">
            <el-table-column prop="province" label="年月日时分">
            </el-table-column>
            <el-table-column prop="city" label="医师签名">
            </el-table-column>
            <el-table-column prop="address" label="护士签名">
            </el-table-column>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableData: [{
        date: '2016-05-03',
        name: '',
        province: '',
        city: '',
        address: '',
        zip: 200333
      }],
      switchIndex: 0
    }
  },
  methods: {
    switchClick (index) {
      this.switchIndex = index
    }
  }
}
</script>

<style lang="less" scoped>
.tab-content {
  width: 100%;
  height: 100%;

  .switch-title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .switch-left {
      display: flex;
      align-items: center;

      .el-button {
        margin-right: 10px;
        border-radius: 0;
        border: none;
        background-color: #D9D9D9;
        color: #666666;
      }

      .button-color {
        background-color: #1269B7;
        color: #fff;
      }
    }

    .switch-right {
      display: flex;
      align-items: center;

      .el-input {
        width: 140px;
        border-color: #D9D9D9;

        ::v-deep input::-webkit-input-placeholder {
          color: #666;
        }
      }

      .el-button {
        margin-left: 10px;
        background-color: #1286EE;
        color: #fff;
        border: none;
        border-radius: 0;
      }
    }
  }

  .pagination {
    display: flex;
    width: 100%;
    height: calc(100% - 60px);
    margin-top: 10px;

    .pagination-scroll {
      overflow-y: scroll;
      width: 212px;
      height: 100%;
      padding: 0 20px;
      box-sizing: border-box;

      &::-webkit-scrollbar {
        width: 0;
      }

      .pagination-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 192px;
        margin-bottom: 10px;
        padding: 10px 9px;
        border: 1px solid #666666;

        .table {
          width: 155px;
          height: 68px;
          margin-top: 30px;
          background-color: #666;
        }

        .scalc {
          transform: scale(0.2);
        }

        .number {
          color: #666666;
          font-size: 12px;
        }
      }
    }

    .pagination-table {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: calc(100% - 192px);
      height: 100%;

      .table-title {
        margin-top: 9px;
        color: #3E3E3E;
        font-size: 20px;
        font-weight: 800;
      }

      .table-line {
        margin-top: 10px;
        color: #3E3E3E;
        font-size: 18px;
        font-weight: 800px;
        letter-spacing: 10px;
      }

      .table-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding-bottom: 6px;
        border-bottom: 1px solid #707070;
        color: #3E3E3E;
        font-size: 16px;
        font-weight: 800px;
      }

      .el-table {
        margin-top: 10px;
      }

      ::v-deep .el-table thead.is-group th.el-table__cell,
      ::v-deep .el-table--border,
      .el-table--group {
        border: 1px solid #707070;
      }

      ::v-deep .el-table td.el-table__cell {
        border: 1px solid #707070;
        color: #3E3E3E;
        text-align: center;
      }

      ::v-deep .el-table__row {
        border-bottom: 1px solid #707070;
      }
    }
  }
}
</style>
