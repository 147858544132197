<template>
  <div class="popup1">
    <div class="title">
      <span>选择干预模板</span>
    </div>
    <div class="content">
      <div @click="closePopup" class="close">
        <span class="el-icon-close"></span>
      </div>
      <el-input placeholder="请输入关键字搜索"></el-input>
      <div class="table">
        <el-table border height="440" :cell-style="tableCellStyle" :header-cell-style="tableHeaderCellStyle"
          :data="tableData">
          <el-table-column v-for="(item, index) in tableKeyValue" :key="index" :prop="item.prop" :label="item.label">
          </el-table-column>
          <el-table-column type="selection">
          </el-table-column>
        </el-table>
        <div class="bottom">
          <div style="width: 100px"></div>
          <div class="bts">
            <span @click="tableClosePopup" class="blue">取消</span>
            <span @click="tableClosePopup">确定</span>
          </div>
          <el-pagination :page-size="100" layout="total, prev, pager, next" :total="1000">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableKeyValue: [
        { prop: 'id', label: '模板标题' },
        { prop: 'name', label: '创建者' },
        { prop: 'type', label: '创建时间' },
        { prop: 'gg', label: '备注' }
      ],
      tableData: [
        { id: '睡眠管理干预', name: 'nnn', type: '2022-10-19', gg: '无' }
      ]
    }
  },
  methods: { // 修改 table cell边框的背景色
    tableCellStyle () {
      return 'border: 1px solid #01EEFD; background-color: #051A2C;text-align: center;border-left: none;border-top: none;'
    },
    // 修改 table header cell的背景色
    tableHeaderCellStyle () {
      return 'border-color: transparent; color: #fff; text-align: center;background-color: #02457B;'
    },
    tableClosePopup () {
      this.$emit('tableClosePopup')
      this.closePopup()
    },
    closePopup () {
      this.$emit('closePopup')
    }
  }
}
</script>

<style lang="less" scoped>
.popup1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 888;
  background-color: #000000;

  .title {
    width: 267px;
    height: 53px;
    padding-top: 5px;
    box-sizing: border-box;
    line-height: 53px;
    text-align: center;
    background-image: url('../../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: 30px;
    font-weight: 500;
  }

  .content {
    position: relative;
    width: 100%;
    height: calc(100% - 63px);
    margin-top: 10px;
    padding: 50px 60px;
    background-image: url('../../img/ssjk/background.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: 18px;
      right: 30px;
      color: #fff;
      font-size: 24px;
    }

    .el-input {
      width: 300px;
      height: 38px;
      margin-bottom: 20px;
      line-height: 38px;
      padding: 0;
    }

    .table {
      width: 100%;
      height: calc(100% - 70px);

      .el-table {
        color: #01EEFD;
        font-size: 14px;
        background: transparent;
      }

      .el-table--border {
        border: 1px solid #01EEFD;
      }

      .el-table::before {
        background-color: transparent;
      }

      /deep/.el-checkbox__input.is-checked .el-checkbox__inner,
      /deep/.el-checkbox__input.is-indeterminate .el-checkbox__inner {
        // background-color: #01EEFD;
        border-color: #01EEFD;
      }

      /deep/.el-checkbox__inner {
        background-color: transparent !important;
      }

      /deep/.el-table__body-wrapper {
        &::-webkit-scrollbar {
          // 整个滚动条
          width: 0; // 纵向滚动条的宽度
          background: rgba(213, 215, 220, 0.3);
          border: none;
        }

        /deep/&::-webkit-scrollbar-track {
          // 滚动条轨道
          border: none;
        }
      }

      /deep/.el-table th.gutter {
        display: none;
        width: 0
      }

      /deep/.el-table colgroup col[name='gutter'] {
        display: none;
        width: 0;
      }

      /deep/.el-table__body {
        width: 100% !important;
      }

      .bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 26px;
        margin-top: 10px;

        .bts {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 164px;
          height: 26px;
          font-size: 14px;
          color: #fff;

          span {
            width: 70px;
            height: 26px;
            line-height: 26px;
            text-align: center;
            background-color: #D2D2D2;
          }

          .blue {
            background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
          }
        }

        /deep/.el-pagination__total {
          color: #fff;
        }
      }
    }
  }
}
</style>
