<template>
  <div class="medical">
    <div class="operate">
      <el-dropdown trigger="click" @command="handleCommand">
        <span class="el-dropdown-link">
        <i class="el-icon-s-tools el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="1">新建</el-dropdown-item>
        <el-dropdown-item command="2">保存</el-dropdown-item>
        <el-dropdown-item command="3">智能生成</el-dropdown-item>
        <el-dropdown-item command="4">存为模板</el-dropdown-item>
        <el-dropdown-item command="5">打印</el-dropdown-item>
        <el-dropdown-item command="6">续打</el-dropdown-item>
        <el-dropdown-item command="7">刷新</el-dropdown-item>
        <el-dropdown-item command="8" @close="close">返回</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    </div>
    <avue-ueditor v-model="text" v-bind="options"></avue-ueditor>
  </div>
</template>

<script>
export default {
  data () {
    return {
      text: `<p style="text-align:center;">
<b>
<font size="5">
健康无忧人民医院
</font>
</b>
</p>
<p style="text-align:center;">
<b>
<font size="5">
</font>
</b>
</p>
<p style="text-align:center;">
<b>
病程记录
</b>
</p>
<p style="text-align:left;">
<b>
</b>
</p>
<p style="">
</p>
<p style="text-align:center;">
<font size="3">
姓名：[李艳艳]&nbsp;年龄：[44岁3月]&nbsp;床号：[T-4]&nbsp;科室：[五官科]&nbsp;病区：[五官科一病区]&nbsp;住院号：[202005562008]
</font>
<span style="letter-spacing: 0.0125rem; font-size: 12px;">
</span>
<span style="letter-spacing: 0.0125rem; font-size: 12px;">
</span>
<span style="letter-spacing: 0.0125rem; font-size: 12px;">
</span>
<span style="letter-spacing: 0.0125rem; font-size: 12px;">
</span>
<span style="letter-spacing: 0.0125rem;">
<br/>
</span>
</p>
<hr/>
<table border="0" width="100%" cellpadding="0" cellspacing="0" style="text-align:left;">
<tbody>
<tr>
<td>
<p>
姓名：
</p>
</td>
<td>
<p>
[李艳艳]
</p>
</td>
<td>
<p>
居住地：
</p>
</td>
<td>
<p>
[彭城]
</p>
</td>
</tr>
<tr>
<td>
<p>
性别：
</p>
</td>
<td>
<p>
[女]
</p>
</td>
<td>
<p>
</p>
<p>
联系方式：
</p>
</td>
<td>
<p>
[15589602564]
</p>
</td>
</tr>
<tr>
<td>
<p>
年龄：
</p>
</td>
<td>
<p>
[44岁3月]
</p>
</td>
<td>
<p>
入院时间：
</p>
</td>
<td>
<p>
[2021-05-25 08:56]
</p>
</td>
</tr>
<tr>
<td>
<p>
民族：
</p>
</td>
<td>
<p>
[汉族]
</p>
</td>
<td>
<p>
病史陈述者：
</p>
</td>
<td>
<p>
[患者本人]
</p>
</td>
</tr>
<tr>
<td>
<p>
婚姻：
</p>
</td>
<td>
<p>
[已婚]
</p>
</td>
<td>
</td>
<td>
</td>
</tr>
</tbody>
</table>
<p>
</p>
<p style="">
主诉：[主诉内容]
</p>
<p>
</p>
<p>
现病史：[现病史内容]
</p>
<p>
</p>
<p>
既往史：[既往史内容]
</p>
<p>
</p>
<p>
个人史：[个人史内容]
</p>
<p>
</p>
<p>
月经史：[月经史内容]
</p>
<p>
</p>
<p>
婚育史：[婚育史内容]
</p>
<p>
</p>
<p>
家族史：[家族史内容]
</p>
<p>
</p>
<p>
注：经核查，确认上述情况准确无误，核实人签字：
</p>
<p>
</p>
<p>
与患者关系(需与委托人一致)：
</p>
<p>
</p>
<p>
体格检查:[体格检查内容]
</p>
<p>
</p>
<p>
转科检查:[转科检查内容]
</p>
<p>
</p>
<p>
辅助检查:[辅助检查内容]
</p>
<p>
</p>
<p>
初步诊断：[西医诊断：1.慢性咽炎 2.慢性鼻炎]
</p>
<p>
<br/>
</p>
<p style="text-align:left;">
<font size="1">
</font>
</p>`,
      options: {}
    }
  },
  watch: {
    text (news) {
      console.log(news)
    }
  },
  methods: {
    handleCommand (command) {
      this.close()
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.medical {
  overflow-y: scroll;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 88;
  width: calc(100% - 290px);
  height: 100%;
  background-color: #fff;

  &::-webkit-scrollbar {
    width: 0;
  }

  .operate {
    position: absolute;
    top: 0;
    right: 10px;
    z-index: 8888;
    font-size: 26px;
  }

  ::v-deep .avue-ueditor {
    width: 100%;
    height: 100%;
  }

  ::v-deep.w-e-text-container,
  ::v-deep .w-e-text {
    height: 100% !important;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  ::v-deep.avue-ueditor {
    >div {
      width: 100%;
      height: calc(100% - 150px);
    }
  }
}
</style>
